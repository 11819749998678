.header {
  // height: 400px;
  background-color: white;
}


.img-thumbnail {
  border: none;
}

.card-border {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(52, 94, 126, 0.4);
  border-radius: .25rem;
  padding: 1.25rem;
  margin: 0px
}

.card {
  border: 1px solid rgba(52, 94, 126, 0.4);
}

.content {
  background-color: White;
  padding:25px;

}

.footer {
  background-color: White;
  padding: 25px 25px 0px 25px;
}

.card-img-top {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 100%;
  max-height: 300px;
  margin-bottom: 1rem;
}


ul {
  padding-left: 30px;
}
